<template>
  <v-dialog v-model="dialog" width="500px">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-badge
            :content="`${trainingRecord.assignmentCount}`"
            color="green"
            overlap
            offset-x="20"
          >
            <v-btn
              v-blur
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
              @click="reloadAssignments++"
            >
              <v-icon color="msaBlue"> mdi-account-multiple </v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>Assignments</span>
      </v-tooltip>
    </template>
    <TrainingRecordAssignments
      :trainingRecord="trainingRecord"
      :key="reloadAssignments"
      :showClose="true"
      @close="dialog = false"
      @detached="$emit('detached')"
      @onAttached="$emit('onAttached', $event)"
      @massManageFirstLoad="$emit('massManageFirstLoad', $event)"
    />
  </v-dialog>
</template>
<script>
import TrainingRecordAssignments from '@/components/TrainingRecordLibrary/TrainingRecordAssignments.vue';
export default {
  name: 'TrainingRecordAssignmentButton',
  components: {
    TrainingRecordAssignments,
  },
  props: {
    trainingRecord: {
      type: Object,
    },
  },
  data() {
    return { dialog: false, reloadAssignments: 1 };
  },
};
</script>
<style scoped></style>
