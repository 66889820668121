<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="getAvailableEmployees()"
          >
            <v-icon color="msaBlue"> mdi-account-multiple-plus </v-icon>
          </v-btn>
        </template>
        <span>Attach Users</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        Attach Users
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="User"
              v-model="filters.employeeName"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <CustomizedAutoComplete
              :items="labels"
              label="Filter by group"
              :value="filters.labelIds"
              hide-details
              clearable
              multiple
              deletable-chips
              @change="filters.labelIds = $event"
            />
          </v-col>
          <v-col cols="12">
            <CustomizedAutoComplete
              :items="supervisors"
              label="Filter by supervisor"
              item-text="fullname"
              :value="filters.supervisorIds"
              hide-details
              clearable
              multiple
              deletable-chips
              @change="filters.supervisorIds = $event"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              class="msaBlue white--text"
              width="100%"
              @click="
                options.page = 1;
                getAvailableEmployees();
              "
            >
              <v-icon class="pr-2">mdi-filter</v-icon>
              Apply filter
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="employees"
                :server-items-length="count"
                show-select
                disable-sort
                disable-filtering
                item-key="id"
                checkbox-color="msaBlue"
                class="training-record-employee-list"
                :options.sync="options"
              >
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="confirm()" class="msaBlue white--text"> confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'TrainingRecordNewAssignments',
  props: {
    trainingRecord: {
      type: Object,
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.getAvailableEmployees();
      },
    },
  },
  computed: {
    isUnsavedDocument() {
      return isNaN(this.trainingRecord.id);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data() {
    return {
      selected: [],
      dialog: false,
      filters: {
        labelIds: [],
        supervisorIds: [],
        employeeName: '',
      },
      loading: false,
      employees: [],
      labels: [],
      supervisors: [],
      headers: [
        {
          text: `User`,
          value: 'fullname',
          class: 'lightGrey',
        },
      ],
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: 10,
      },
      count: 0,
    };
  },
  methods: {
    getAvailableEmployees() {
      if (this.filters.employeeName == null) {
        this.filters.employeeName = '';
      }
      const params = {
        filters: this.filters,
        trainingRecordId: this.trainingRecord.id,
        loaderText: 'loading...',
        options: this.options,
      };
      const url = 'get-employees-for-attaching-to-training-record?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.employees = response.data.employees;

          if (this.isUnsavedDocument) {
            this.selected = this.trainingRecord.attachedEmployees;
          }

          this.count = response.data.count;
        })
        .catch((error) => error);
    },
    getlabelsAndSupervisors() {
      const params = {
        loaderText: 'loading...',
      };
      const url =
        'get-data-for-attaching-employees-to-training-record?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.labels = response.data.labels;
          this.supervisors = response.data.supervisors;
        })
        .catch((error) => error);
    },
    confirm() {
      if (!this.isUnsavedDocument && this.selected.length > 0) {
        const params = {
          loaderText: 'loading...',
          employeeIds: this.selected.map((i) => i.id),
          trainingRecordId: this.trainingRecord.id,
        };
        const url = 'attach-employees-to-training-record?format=json';
        this.$axios
          .post(url, params)
          .then(() => this.$emit('confirmed', this.selected))
          .catch((error) => error);
      } else {
        this.$emit('confirmed', this.selected);
      }
      this.dialog = false;
    },
    close() {
      this.selected = [];
      this.filters = {
        labelIds: [],
        supervisorIds: [],
        employeeName: '',
      };
      this.dialog = false;
    },
  },
  mounted() {
    this.getlabelsAndSupervisors();
  },
};
</script>
<style>
.training-record-employee-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
