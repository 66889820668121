<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn v-blur icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
            <v-icon color="red" v-if="!isDeleted"> mdi-delete </v-icon>
            <v-icon color="msaBlue" v-if="isDeleted">
              mdi-delete-restore
            </v-icon>
          </v-btn>
        </template>
        <span>{{ isDeleted ? 'Restore' : 'Delete' }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        {{ isDeleted ? 'Restore' : 'Delete' }} Document
      </v-card-title>
      <v-card-text class="mt-2 text-body-1" v-if="!isDeleted">
        Are you sure you want to delete this document?
      </v-card-text>
      <v-card-text class="mt-2 text-body-1" v-if="isDeleted">
        Are you sure you want to restore this document?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="dialog = false"
          text
          class="msaBlue--text font-weight-bold"
        >
          Cancel
        </v-btn>

        <v-btn @click="confirm()" class="msaBlue white--text"> confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'TrainingRecordDeletion',
  props: {
    trainingRecord: {
      type: Object,
    },
  },
  computed: {
    isDeleted() {
      return this.trainingRecord.deletedAt != '';
    },
  },
  data() {
    return { dialog: false };
  },
  methods: {
    confirm() {
      const params = {
        loaderText: 'saving...',
      };

      let url = '';

      if (this.isDeleted) {
        params.trainingRecordId = this.trainingRecord.id;
        url = 'restore-training-record?format=json';
      } else {
        params.trainingRecordIds = [this.trainingRecord.id];
        url = 'delete-training-records?format=json';
      }

      this.$axios
        .post(url, params)
        .then(() => {
          this.dialog = false;
          this.$emit('confirmed');
        })
        .catch((error) => error);
    },
    close() {
      this.dialog = false;
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
