<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom v-if="!forUploader && group.id == 0">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <span>Add new folder</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!forUploader && group.id != 0">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            :text="!isGroupExpanded"
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
          >
            <v-icon color="msaBlue"> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
      <v-btn
        v-if="forUploader"
        v-blur
        v-bind="attrs"
        class="msaBlue white--text"
        v-on="{ ...dialog }"
        @click="popData()"
      >
        <v-icon class="pr-2"> mdi-plus </v-icon>
        Add Folder
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        {{ modalTitle }}
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <v-text-field
              v-model="edited.name"
              outlined
              dense
              label="Folder name"
              maxlength="200"
              counter
              :rules="required"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="close()"> cancel</v-btn>
        <v-btn
          class="msaBlue white--text"
          :disabled="disableSave"
          @click="save()"
        >
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'TrainingRecordGroupDetail',
  props: {
    group: {
      type: Object,
      default() {
        return {
          id: 0,
          name: '',
          position: 0,
          trainingRecordCount: 0,
          nameSortOrder: 0,
        };
      },
    },
    isGroupExpanded: {
      type: Boolean,
    },
    forUploader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalTitle() {
      return this.edited.id == 0 ? 'Add New Folder' : 'Edit Folder';
    },
    disableSave() {
      return (
        this.edited.name == this.group.name || this.edited.name.trim() == ''
      );
    },
  },
  data() {
    return {
      dialog: false,
      edited: { name: '' },
      required: [(v) => !!v || 'This field is required'],
    };
  },
  methods: {
    popData() {
      this.edited = { ...this.group };
    },
    close() {
      this.$emit('closed');
      this.dialog = false;
    },
    save() {
      const params = {
        loaderText: 'loading...',
        group: this.edited,
      };
      const url =
        this.edited.id == 0
          ? 'create-training-record-group?format=json'
          : 'edit-training-record-group?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.$emit(
            'saved',
            this.edited.id == 0 ? response.data : this.edited,
          );
          this.close();
        })
        .catch((error) => error);
    },
  },
  mounted() {},
};
</script>
