<template>
  <span>
    <v-data-table
      v-model="thisSelected"
      :headers="headers"
      :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
      :items="trainingRecords"
      :server-items-length="itemCount"
      :loading="loading"
      :show-select="$route.name == 'CompanyTrainingRecords'"
      must-sort
      :item-class="rowClass"
      checkbox-color="msaBlue"
      class="training-record-list"
      :options.sync="tableOptions"
      @item-selected="onSelect($event)"
      @toggle-select-all="onSelect($event)"
    >
      <template v-slot:[`item.name`]="{ item }">
        <span
          v-if="item.deletedAt == ''"
          @click="downloadDocument(item)"
          class="msaBlue--text"
          :style="{ cursor: 'pointer' }"
        >
          {{ item.name }}
        </span>
        <span v-else>
          {{ item.name }}
        </span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | filterAsLocalDate }}
      </template>
      <template v-slot:[`item.completedAt`]="{ item }">
        {{ item.completedAt | filterAsLocalDate }}
      </template>
      <template v-slot:[`item.expireAt`]="{ item }">
        {{ item.expireAt | filterAsLocalDate }}
      </template>
      <template
        v-slot:[`item.actions`]="{ item }"
        v-if="$route.name == 'CompanyTrainingRecords'"
      >
        <v-tooltip bottom v-if="item.deletedAt == ''">
          <template v-slot:activator="{ on }">
            <v-btn @click="goToDetail(item)" icon color="msaBlue" v-on="on">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <PublishToggle
          :is-published="item.isPublished"
          @click="togglePublish(item)"
        />
        <TrainingRecordAssignmentButton
          v-if="item.deletedAt == ''"
          :trainingRecord="item"
          @detached="item.assignmentCount--"
          @onAttached="item.assignmentCount += $event.length"
        />
        <TrainingRecordDeletion
          :trainingRecord="item"
          @confirmed="handleDeletionConfirmed(item.id)"
        />
      </template>
    </v-data-table>
  </span>
</template>

<script>
import TrainingRecordDeletion from '@/components/TrainingRecordLibrary/TrainingRecordDeletion.vue';
import TrainingRecordAssignmentButton from '@/components/TrainingRecordLibrary/TrainingRecordAssignmentButton.vue';
import PublishToggle from '@/components/PublishToggle';
export default {
  name: 'TrainingRecordList',
  components: {
    TrainingRecordDeletion,
    TrainingRecordAssignmentButton,
    PublishToggle,
  },
  props: {
    filters: {
      type: Object,
    },
    groupId: {
      type: Number,
    },
    selected: {
      type: Array,
    },
    groups: {
      type: Array,
    },
  },
  watch: {
    groupId() {
      this.getTrainingRecords();
    },
    tableOptions: {
      handler(val) {
        this.$emit('optionChanged', JSON.parse(JSON.stringify(val)));
        this.getTrainingRecords();
      },
      deep: true,
    },
    filters: {
      handler() {
        this.tableOptions.page = 1;
        this.getTrainingRecords();
      },
      deep: true,
    },
    selected() {
      this.checkSelected();
    },
  },
  data() {
    return {
      trainingRecords: [],
      loading: false,
      thisSelected: [],
      page: this.$constants.PAGINATION.DEFAULT_PAGE,
      perPage: 20,
      itemCount: 0,
      tableOptions:
        this.$store.getters.persistentSettings[this.$route.name]?.options,
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
        },
        {
          text: 'Created Date',
          value: 'createdAt',
          class: 'lightGrey',
          width: '150px',
        },
        {
          text: 'Completed Date',
          value: 'completedAt',
          width: '150px',
          class: 'lightGrey',
          align: 'center',
        },
        {
          text: 'Recertification Date',
          value: 'expireAt',
          width: '160px',
          class: 'lightGrey',
          align: 'center',
        },
        {
          text: 'Duration Hrs',
          value: 'duration',
          width: '120px',
          class: 'lightGrey',
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          width: '180px',
          class: 'lightGrey',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    goToDetail(trainingRecord) {
      this.$store.commit('updateSelectedTrainingRecord', trainingRecord);
      this.$router.push({
        name: 'CompanyTrainingRecordDetails',
        params: {
          id: trainingRecord.id,
        },
      });
    },
    getTrainingRecords() {
      this.loading = true;
      const params = {
        filters: this.filters,
        groupId: this.groupId,
        options: this.tableOptions,
      };

      const url = 'get-training-records-by-group?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.trainingRecords = response.data.trainingRecords;
          this.trainingRecords.forEach((t) => {
            t.deletedAt == ''
              ? (t.isSelectable = true)
              : (t.isSelectable = false);
          });
          this.itemCount = response.data.count;
          this.checkSelected();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          return error;
        });
    },
    checkSelected() {
      this.thisSelected = this.trainingRecords.filter((record) =>
        this.selected.some((selected) => selected.id == record.id),
      );
    },
    downloadDocument(doc) {
      const params = {
        id: doc.id,
        isArchived: false,
        loaderText: 'loading...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('download-training-record?format=json', params, options)
        .then((response) => {
          this.$helpers.previewFileInNewWindow(response.data);
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$root.showMessage(
              'File not found',
              'File does not exist.',
              () => false,
              null,
              'OK',
            );
          } else {
            return error;
          }
        });
    },
    onSelect(event) {
      if (event.item != undefined) {
        this.onSelectOne(event);
      }

      // Handle event when user uses SHIFT to select multiple items
      if (event.currentItem != undefined) {
        event.item = event.currentItem;
        this.onSelectOne(event);
      }

      if (event.items != undefined) {
        this.onSelectAll(event);
      }
    },
    onSelectOne(event) {
      this.$emit('onSelect', {
        docs: [event.item],
        value: event.value,
      });
    },
    onSelectAll(event) {
      if (event.value) {
        this.thisSelected = event.items.filter((t) => t.deletedAt == '');
        this.$emit('onSelect', {
          docs: this.thisSelected,
          value: event.value,
        });
      } else {
        this.thisSelected = [];
        this.$emit('onSelect', {
          docs: event.items,
          value: event.value,
        });
      }
    },
    togglePublish(trainingRecord) {
      const params = {
        trainingRecordId: trainingRecord.id,
        loaderText: 'loading...',
        snackText: `The record ${trainingRecord.name} was successfully ${
          trainingRecord.isPublished ? 'unpublished' : 'published'
        }`,
      };

      const url = 'toggle-publish-for-training-record?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.showPublishSnackbar = trainingRecord;
          trainingRecord.isPublished = trainingRecord.isPublished ? 0 : 1;
        })
        .catch((error) => error);
    },
    rowClass(item) {
      if (item.isSelectable) {
        return '';
      }
      return 'red--text';
    },
    handleDeletionConfirmed(trainingRecordId) {
      const index = this.thisSelected.findIndex(
        (t) => t.id == trainingRecordId,
      );
      if (index != -1) {
        this.onSelectOne({ item: this.thisSelected[index], value: false });
      }
      this.getTrainingRecords();
    },
  },
  mounted() {
    this.tableOptions =
      this.$store.getters.persistentSettings[this.$route.name].options;
  },
};
</script>

<style>
.training-record-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
