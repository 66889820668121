<template>
  <v-container fluid :class="selected.length > 0 ? 'pb-16' : ''">
    <v-row v-if="!isOrdering">
      <v-col cols="12" md="6">
        <SearchBar
          v-if="!isOrdering"
          @search="onSearch"
          ref="searchBar"
          searchLabel="Search by user name..."
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        class="shrink"
        v-if="
          $store.getters.isWebAdmin && $route.name == 'CompanyTrainingRecords'
        "
      >
        <TrainingRecordGroupDetail
          @saved="getGroups()"
          @closed="reloadGroupDetail++"
          :key="reloadGroupDetail"
          :users="users"
        />
      </v-col>
      <v-col
        class="shrink"
        v-if="
          $store.getters.isWebAdmin && $route.name == 'CompanyTrainingRecords'
        "
      >
        <TrainingRecordUploadDialog
          :key="reloadGroups + 1"
          :groups="fullGroups"
          :users="users"
          @saved="onSaved"
          @groupAdded="getGroups()"
        />
      </v-col>
      <v-col class="shrink">
        <TrainingRecordLibraryFilter
          ref="filters"
          :groups="fullGroups"
          :filters="filters"
          @applyFilters="applyFilters($event)"
        />
      </v-col>
      <v-col class="shrink">
        <TrainingRecordLibraryExport :filters="filters" />
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-6" v-if="groups.length == 0"
      >No results found</v-row
    >
    <v-row :key="reloadGroups">
      <v-col>
        <v-expansion-panels
          transition="fade-transition"
          v-model="expanded"
          active-class="msaBlue white--text"
        >
          <v-expansion-panel
            v-for="(group, index) in groups"
            :key="index"
            class="my-2"
          >
            <v-expansion-panel-header
              hide-actions
              v-slot="{ open }"
              :style="headerStyles(index)"
            >
              <v-row no-gutters align="center">
                <v-col class="shrink" :cols="isMobile ? 1 : false">
                  <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col
                  class="grow pl-4 font-weight-medium d-flex align-self-center"
                  :cols="isMobile ? 11 : false"
                >
                  {{ group.name }}
                  <div class="ml-2"></div>
                  <v-badge
                    class="align-self-center"
                    :bordered="open"
                    :content="`${group.trainingRecordCount}`"
                    offset-y="10"
                  ></v-badge>
                </v-col>
                <v-col
                  align="right"
                  v-if="$route.name == 'CompanyTrainingRecords'"
                >
                  <v-row dense>
                    <v-spacer></v-spacer>
                    <v-col
                      v-if="
                        !isOrdering &&
                        isGroupEditable(group.name) &&
                        $store.getters.isWebAdmin &&
                        $route.name == 'CompanyTrainingRecords'
                      "
                    >
                      <TrainingRecordGroupDetail
                        :group="group"
                        :isGroupExpanded="open"
                        @saved="handleGroupEdited($event)"
                      />
                    </v-col>
                    <v-col
                      v-if="
                        !isOrdering &&
                        !filters.filterApplied &&
                        !isSearching &&
                        $store.getters.isWebAdmin &&
                        $route.name == 'CompanyTrainingRecords'
                      "
                      class="shrink"
                      align="right"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-blur
                            :text="!open"
                            small
                            v-on="on"
                            @click.native="
                              startOrderGroups($event);
                              group.isOrdering = true;
                            "
                          >
                            <v-icon> mdi-swap-vertical </v-icon>
                          </v-btn>
                        </template>
                        <span>Change Position</span>
                      </v-tooltip>
                    </v-col>
                    <v-col v-if="group.isOrdering">
                      <ChangeOrderButtonGroup
                        :currentIndex="index"
                        :maxIndex="groups.length - 1"
                        @updatePositions="updateGroupPositions()"
                        @onOrder="changeGroupOrder($event)"
                      />
                    </v-col>
                    <v-col
                      class="shrink"
                      v-if="
                        !isOrdering &&
                        isGroupEditable(group.name) &&
                        $store.getters.isWebAdmin &&
                        $route.name == 'CompanyTrainingRecords'
                      "
                    >
                      <TrainingRecordGroupDeletion
                        :trainingRecordGroup="group"
                        :isGroupExpanded="open"
                        @confirmed="handleGroupDeletion(group)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <TrainingRecordList
                ref="list"
                :filters="filters"
                :selected="selected"
                :groupId="group.id"
                :groups="fullGroups"
                @onSelect="handleSelection($event)"
                @optionChanged="updateOptions($event)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <TrainingRecordSelectionModal
      :selectedItems="selected"
      v-if="selected.length > 0 && !isOrdering"
      @unselectAll="onUnselectAll"
      @onUnselect="handleUnselect($event)"
      @onSave="handleMassManageAfterSave()"
    />
  </v-container>
</template>
<script>
import ChangeOrderButtonGroup from '@/components/ChangeOrderButtonGroup.vue';
import SearchBar from '@/components/SearchBar.vue';
import TrainingRecordGroupDeletion from '@/components/TrainingRecordLibrary/TrainingRecordGroupDeletion.vue';
import TrainingRecordGroupDetail from '@/components/TrainingRecordLibrary/TrainingRecordGroupDetail.vue';
import TrainingRecordLibraryExport from '@/components/TrainingRecordLibrary/TrainingRecordLibraryExport.vue';
import TrainingRecordLibraryFilter from '@/components/Filters/TrainingRecordLibraryFilter.vue';
import TrainingRecordList from '@/components/TrainingRecordLibrary/TrainingRecordList.vue';
import TrainingRecordSelectionModal from '@/components/TrainingRecordLibrary/TrainingRecordSelectionModal.vue';
import TrainingRecordUploadDialog from '@/components/TrainingRecordLibrary/TrainingRecordUploadDialog.vue';

export default {
  name: 'TrainingRecordLibraryPage',
  components: {
    ChangeOrderButtonGroup,
    SearchBar,
    TrainingRecordGroupDeletion,
    TrainingRecordGroupDetail,
    TrainingRecordLibraryExport,
    TrainingRecordLibraryFilter,
    TrainingRecordList,
    TrainingRecordSelectionModal,
    TrainingRecordUploadDialog,
  },
  watch: {
    expanded(val) {
      if (val !== undefined) {
        this.expandedGroupId = this.groups[val].id;
      } else {
        this.expandedGroupId = undefined;
      }
    },
  },
  computed: {
    headerStyles() {
      return (index) =>
        index == this.expanded
          ? {}
          : {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            };
    },
    isGroupEditable() {
      return (groupName) => groupName !== 'Default';
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isSearching() {
      return this.filters.search.trim().length > 0;
    },
  },
  data() {
    const persistentSettings = this.$store.getters.persistentSettings;
    return {
      groups: [],
      filters: {
        ...this.$constants.TRAINING_RECORD_LIBRARY_FILTERS,
      },
      expanded: undefined,
      expandedGroupId: persistentSettings[this.$route.name].expandedGroupId,
      selected: [],
      isOrdering: false,
      reloadGroupDetail: 3,
      reloadGroups: 999,
      fullGroups: [],
      users: [],
      options: JSON.parse(
        JSON.stringify(persistentSettings[this.$route.name].options),
      ),
    };
  },
  methods: {
    onSaved() {
      this.setOptionsTopage1();
      this.getGroups();
      this.reloadGroups++;
    },
    setOptionsTopage1() {
      let options =
        this.$store.getters.persistentSettings[this.$route.name]?.options;
      options.page = 1;
      this.$store.commit('updatePersistentSettings', {
        key: 'CompanyTrainingRecords',
        value: {
          expandedGroupId: this.expandedGroupId,
          options: { ...options },
        },
      });
    },
    getGroups() {
      const params = {
        filters: this.filters,
        loaderText: 'Loading...',
      };
      const url = 'get-training-record-groups-by-company?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.groups = response.data.groups;
          this.fullGroups = response.data.fullGroups;
          this.getUsers();

          this.setupExpanded();
        })
        .catch((error) => error);
    },
    getUsers() {
      const userOptions = {
        itemsPerPage: -1,
        page: 1,
      };

      const userFilters = {
        labelIds: [],
        supervisorIds: [],
        employeeName: '',
      };

      const params = {
        options: userOptions,
        trainingRecordId: false,
        filters: userFilters,
      };
      const url = 'get-employees-for-attaching-to-training-record?format=json';

      this.$axios.post(url, params).then((response) => {
        this.users = response.data.employees;
      });
    },
    // Insure css styles are applied to the correct group when the groups change
    setupExpanded() {
      const index = this.groups.findIndex((g) => g.id == this.expandedGroupId);

      if (index != -1) {
        this.expanded = index;
      }
    },
    handleGroupDeletion(group) {
      if (group.id == this.expandedGroupId) {
        this.expandedGroupId = undefined;
        this.expanded = undefined;
      }
      let index = this.groups.findIndex((g) => g.id == group.id);
      this.groups.splice(index, 1);
      index = this.fullGroups.findIndex((g) => g.id == group.id);
      this.fullGroups.splice(index, 1);
    },
    changeGroupOrder(event) {
      this.expanded = undefined;
      this.expandedGroupId = undefined;
      const movedItem1 = { ...this.groups[event.index] };

      const movedItem2 = {
        ...this.groups[event.index + event.value],
      };

      this.$set(this.groups, event.index, movedItem2);
      this.$set(this.groups, event.index + event.value, movedItem1);
    },
    startOrderGroups(e) {
      // prevent group to expand
      e.stopPropagation();
      this.isOrdering = true;
      this.expanded = undefined;
      this.expandedGroupId = undefined;
    },
    updateGroupPositions() {
      this.isOrdering = false;
      const fullGroups = [];
      const groups = this.groups.map((g, index) => {
        g.isOrdering = false;
        const fg = this.fullGroups.find((fg) => fg.id == g.id);
        fullGroups.push(fg);
        return {
          id: g.id,
          position: index + 1,
        };
      });

      const params = {
        trainingRecordGroups: groups,
        loaderText: 'Loading...',
      };

      const url = 'update-training-record-group-positions?format=json';
      this.$axios.post(url, params).then(() => (this.fullGroups = fullGroups));
    },
    handleGroupEdited(editedGroup) {
      let index = this.groups.findIndex((g) => g.id == editedGroup.id);
      this.$set(this.groups, index, editedGroup);
      index = this.fullGroups.findIndex((g) => g.id == editedGroup.id);
      this.$set(this.fullGroups, index, editedGroup);
    },
    handleSelection(event) {
      if (event.value) {
        this.selected = this.selected
          .concat(event.docs)
          // remove duplicates
          .filter(
            (item, index, self) =>
              self.findIndex((t) => t.id == item.id) == index,
          );
      } else {
        this.selected = this.selected.filter(
          (selected) => !event.docs.some((doc) => doc.id == selected.id),
        );
      }
    },
    handleUnselect(event) {
      this.selected = this.selected.filter(
        (selected) => selected.id != event.id,
      );
    },
    onUnselectAll() {
      this.selected = [];
    },
    handleMassManageAfterSave() {
      this.setOptionsTopage1();
      this.getGroups();
      this.selected = [];
      this.reloadGroups++;
    },
    applyFilters(filters) {
      this.filters = { ...filters };
      this.options.page = 1;
      const original = JSON.parse(
        JSON.stringify(this.$constants.TRAINING_RECORD_LIBRARY_FILTERS),
      );
      const _filters = JSON.parse(JSON.stringify(this.filters));
      delete original.filterApplied;
      delete _filters.filterApplied;
      this.filters.filterApplied =
        JSON.stringify(original) == JSON.stringify(_filters) ? 0 : 1;
      this.$store.commit('updatePersistentSettings', {
        key: 'CompanyTrainingRecords',
        value: {
          expandedGroupId: this.expandedGroupId,
          options: {
            page: this.$constants.PAGINATION.DEFAULT_PAGE,
            itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
            sortBy: ['name'],
            sortDesc: [false],
          },
        },
      });
      this.updateUrl();
    },
    getRouteQueries() {
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(query, this.filters);
      this.getGroups();
    },
    updateUrl() {
      const params = this.$helpers.serializeFilter(
        this.filters,
        this.$constants.TRAINING_RECORD_LIBRARY_FILTERS,
      );

      this.$router
        .replace({ name: this.$route.name, query: params })
        // this is to make sure the page is refreshed when the url is the same
        // in case when assignment changes
        // this is a work around for this issue
        // https://www.pivotaltracker.com/story/show/185847842
        .catch(() => this.$router.go());
    },
    onSearch(event) {
      this.filters.search = event;
      this.options.page = 1;
      const original = JSON.parse(
        JSON.stringify(this.$constants.TRAINING_RECORD_LIBRARY_FILTERS),
      );
      const filters = JSON.parse(JSON.stringify(this.filters));
      delete original.filterApplied;
      delete filters.filterApplied;
      this.filters.filterApplied =
        JSON.stringify(original) == JSON.stringify(filters) ? 0 : 1;
      this.$store.commit('updatePersistentSettings', {
        key: 'CompanyTrainingRecords',
        value: {
          expandedGroupId: this.expandedGroupId,
          options: {
            page: this.$constants.PAGINATION.DEFAULT_PAGE,
            itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
            sortBy: ['name'],
            sortDesc: [false],
          },
        },
      });
      this.updateUrl();
    },
    updateOptions(payload) {
      this.$store.commit('updatePersistentSettings', {
        key: 'CompanyTrainingRecords',
        value: {
          expandedGroupId: this.expandedGroupId ? this.expandedGroupId : -1,
          options: payload,
        },
      });
    },
  },
  mounted() {
    this.getRouteQueries();
    this.$nextTick(() => {
      if (this.filters.search != '') {
        this.$refs.searchBar.setSearch(this.filters.search);
      }
    });
  },
};
</script>
