<template>
  <v-card>
    <v-card-title class="msaBlue white--text">
      <v-row no-gutters align="center">
        <v-col class="grow"> Attached Users </v-col>
        <v-col class="shrink" v-if="showClose">
          <v-btn small icon color="white" @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      hide-default-footer
      :items="attachedEmployees"
      :loading="loading"
      :disable-sort="isMobile"
      :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
      disable-pagination
    >
      <template v-if="!trainingRecord.isEditable" v-slot:top>
        <v-alert
          color="#FEF4E4"
          dark
          :style="{ color: '#B98A38' }"
          class="mb-0"
        >
          This record was <b>automatically generated from</b> an online training
          course and <b>therefore cannot be edited</b>.
        </v-alert>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="trainingRecord.isEditable"
              v-on="on"
              @click="detachEmployee(item.id)"
              text
              small
              color="red"
            >
              <v-icon>mdi-account-minus</v-icon>
            </v-btn>
          </template>
          <span>Detach</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-card-actions v-if="trainingRecord.isEditable">
      <v-spacer></v-spacer>
      <TrainingRecordNewAssignments
        :trainingRecord="trainingRecord"
        :key="reloadNewAssignments"
        @confirmed="getAttachedEmployees($event)"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import TrainingRecordNewAssignments from '@/components/TrainingRecordLibrary/TrainingRecordNewAssignments.vue';
export default {
  name: 'TrainingRecordAssignments',
  components: {
    TrainingRecordNewAssignments,
  },
  props: {
    trainingRecord: {
      type: Object,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isUnsavedDocument() {
      return isNaN(this.trainingRecord.id);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'User',
          value: 'fullname',
          class: 'lightGrey',
        },
        {
          text: 'Actions',
          value: 'actions',
          width: '80px',
          class: 'lightGrey',
          align: 'center',
          sortable: false,
        },
      ],
      attachedEmployees: [],
      loading: false,
      reloadNewAssignments: 2,
    };
  },
  methods: {
    load() {
      if (this.isUnsavedDocument) {
        this.attachedEmployees = this.trainingRecord.attachedEmployees;
      } else {
        this.loading = true;
        const params = {
          trainingRecordId: this.trainingRecord.id,
        };
        const url = 'get-attached-employees-for-training-record?format=json';
        this.$axios
          .post(url, params)
          .then((response) => {
            this.attachedEmployees = response.data;
            this.loading = false;
          })
          .catch((error) => error);
      }
    },
    getAttachedEmployees(event) {
      this.reloadNewAssignments++;
      this.$emit('onAttached', event);
      if (this.isUnsavedDocument) {
        this.attachedEmployees = event;
      } else {
        this.load();
      }
    },
    detachEmployee(userId) {
      if (!this.isUnsavedDocument) {
        const params = {
          employeeId: userId,
          trainingRecordId: this.trainingRecord.id,
          loaderText: 'loading...',
        };
        const url = 'detach-employee-from-training-record?format=json';
        this.$axios
          .post(url, params)
          .then(() => {
            this.onDetached(userId);
            this.$emit('detached');
          })
          .catch((error) => error);
      } else {
        this.onDetached(userId);
        this.$emit('onAttached', this.attachedEmployees);
      }
    },
    onDetached(userId) {
      const index = this.attachedEmployees.findIndex((e) => e.id == userId);
      this.attachedEmployees.splice(index, 1);
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style></style>
