import { render, staticRenderFns } from "./TrainingRecordAssignmentButton.vue?vue&type=template&id=015f41ab&scoped=true"
import script from "./TrainingRecordAssignmentButton.vue?vue&type=script&lang=js"
export * from "./TrainingRecordAssignmentButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "015f41ab",
  null
  
)

export default component.exports