var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:_vm.selected.length > 0 ? 'pb-16' : '',attrs:{"fluid":""}},[(!_vm.isOrdering)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(!_vm.isOrdering)?_c('SearchBar',{ref:"searchBar",attrs:{"searchLabel":"Search by user name..."},on:{"search":_vm.onSearch}}):_vm._e()],1),_c('v-spacer'),(
        _vm.$store.getters.isWebAdmin && _vm.$route.name == 'CompanyTrainingRecords'
      )?_c('v-col',{staticClass:"shrink"},[_c('TrainingRecordGroupDetail',{key:_vm.reloadGroupDetail,attrs:{"users":_vm.users},on:{"saved":function($event){return _vm.getGroups()},"closed":function($event){_vm.reloadGroupDetail++}}})],1):_vm._e(),(
        _vm.$store.getters.isWebAdmin && _vm.$route.name == 'CompanyTrainingRecords'
      )?_c('v-col',{staticClass:"shrink"},[_c('TrainingRecordUploadDialog',{key:_vm.reloadGroups + 1,attrs:{"groups":_vm.fullGroups,"users":_vm.users},on:{"saved":_vm.onSaved,"groupAdded":function($event){return _vm.getGroups()}}})],1):_vm._e(),_c('v-col',{staticClass:"shrink"},[_c('TrainingRecordLibraryFilter',{ref:"filters",attrs:{"groups":_vm.fullGroups,"filters":_vm.filters},on:{"applyFilters":function($event){return _vm.applyFilters($event)}}})],1),_c('v-col',{staticClass:"shrink"},[_c('TrainingRecordLibraryExport',{attrs:{"filters":_vm.filters}})],1)],1):_vm._e(),(_vm.groups.length == 0)?_c('v-row',{staticClass:"mt-6",attrs:{"justify":"center"}},[_vm._v("No results found")]):_vm._e(),_c('v-row',{key:_vm.reloadGroups},[_c('v-col',[_c('v-expansion-panels',{attrs:{"transition":"fade-transition","active-class":"msaBlue white--text"},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},_vm._l((_vm.groups),function(group,index){return _c('v-expansion-panel',{key:index,staticClass:"my-2"},[_c('v-expansion-panel-header',{style:(_vm.headerStyles(index)),attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function({ open }){return [_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"shrink",attrs:{"cols":_vm.isMobile ? 1 : false}},[(open)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-minus-thick")]):_c('v-icon',[_vm._v("mdi-plus-thick")])],1),_c('v-col',{staticClass:"grow pl-4 font-weight-medium d-flex align-self-center",attrs:{"cols":_vm.isMobile ? 11 : false}},[_vm._v(" "+_vm._s(group.name)+" "),_c('div',{staticClass:"ml-2"}),_c('v-badge',{staticClass:"align-self-center",attrs:{"bordered":open,"content":`${group.trainingRecordCount}`,"offset-y":"10"}})],1),(_vm.$route.name == 'CompanyTrainingRecords')?_c('v-col',{attrs:{"align":"right"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-spacer'),(
                      !_vm.isOrdering &&
                      _vm.isGroupEditable(group.name) &&
                      _vm.$store.getters.isWebAdmin &&
                      _vm.$route.name == 'CompanyTrainingRecords'
                    )?_c('v-col',[_c('TrainingRecordGroupDetail',{attrs:{"group":group,"isGroupExpanded":open},on:{"saved":function($event){return _vm.handleGroupEdited($event)}}})],1):_vm._e(),(
                      !_vm.isOrdering &&
                      !_vm.filters.filterApplied &&
                      !_vm.isSearching &&
                      _vm.$store.getters.isWebAdmin &&
                      _vm.$route.name == 'CompanyTrainingRecords'
                    )?_c('v-col',{staticClass:"shrink",attrs:{"align":"right"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({directives:[{name:"blur",rawName:"v-blur"}],attrs:{"text":!open,"small":""},nativeOn:{"click":function($event){_vm.startOrderGroups($event);
                            group.isOrdering = true;}}},on),[_c('v-icon',[_vm._v(" mdi-swap-vertical ")])],1)]}}],null,true)},[_c('span',[_vm._v("Change Position")])])],1):_vm._e(),(group.isOrdering)?_c('v-col',[_c('ChangeOrderButtonGroup',{attrs:{"currentIndex":index,"maxIndex":_vm.groups.length - 1},on:{"updatePositions":function($event){return _vm.updateGroupPositions()},"onOrder":function($event){return _vm.changeGroupOrder($event)}}})],1):_vm._e(),(
                      !_vm.isOrdering &&
                      _vm.isGroupEditable(group.name) &&
                      _vm.$store.getters.isWebAdmin &&
                      _vm.$route.name == 'CompanyTrainingRecords'
                    )?_c('v-col',{staticClass:"shrink"},[_c('TrainingRecordGroupDeletion',{attrs:{"trainingRecordGroup":group,"isGroupExpanded":open},on:{"confirmed":function($event){return _vm.handleGroupDeletion(group)}}})],1):_vm._e()],1)],1):_vm._e()],1)]}}],null,true)}),_c('v-expansion-panel-content',[_c('TrainingRecordList',{ref:"list",refInFor:true,attrs:{"filters":_vm.filters,"selected":_vm.selected,"groupId":group.id,"groups":_vm.fullGroups},on:{"onSelect":function($event){return _vm.handleSelection($event)},"optionChanged":function($event){return _vm.updateOptions($event)}}})],1)],1)}),1)],1)],1),(_vm.selected.length > 0 && !_vm.isOrdering)?_c('TrainingRecordSelectionModal',{attrs:{"selectedItems":_vm.selected},on:{"unselectAll":_vm.onUnselectAll,"onUnselect":function($event){return _vm.handleUnselect($event)},"onSave":function($event){return _vm.handleMassManageAfterSave()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }