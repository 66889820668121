<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            :text="!isGroupExpanded"
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon color="red"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text"> Are you sure? </v-card-title>
      <v-card-text
        class="mt-2 text-body-1"
        v-if="trainingRecordGroup.trainingRecordCount > 0"
      >
        Deleting this group will also delete its
        {{ trainingRecordGroup.trainingRecordCount }} training record(s).
      </v-card-text>
      <v-card-text
        class="mt-2 text-body-1"
        v-if="trainingRecordGroup.trainingRecordCount == 0"
      >
        This action cannot be undone.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="dialog = false"
          text
          class="msaBlue--text font-weight-bold"
        >
          Cancel
        </v-btn>

        <v-btn @click="confirm()" class="msaBlue white--text"> confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'TrainingRecordGroupDeletion',
  props: {
    trainingRecordGroup: {
      type: Object,
    },
    isGroupExpanded: {
      type: Boolean,
    },
  },
  data() {
    return { dialog: false };
  },
  methods: {
    confirm() {
      const params = {
        loaderText: 'deleting...',
        trainingRecordGroupId: this.trainingRecordGroup.id,
      };

      const url = 'delete-training-record-group?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.dialog = false;
          this.$emit('confirmed');
        })
        .catch(error => error);
    },
    close() {
      this.dialog = false;
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
