<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn class="msaBlue white--text" @click="exportLibrary()" v-on="on">
        <v-icon> mdi-download </v-icon>
      </v-btn>
    </template>
    Export
  </v-tooltip>
</template>
<script>
export default {
  name: 'TrainingRecordLibraryExport',
  props: {
    filters: {
      type: Object,
    },
  },
  methods: {
    exportLibrary() {
      const params = {
        filters: this.filters,
        loaderText: 'Waiting...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('export-training-record-library?format=json', params, options)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Training Record Library Export.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => error);
    },
  },
};
</script>
